
.market-fng-gauge .market-fng-gauge__overview {
    display: block;
    position: relative;
    width: 100%;
    text-align: left;
}
.market-fng-gauge .market-fng-gauge__meter-container {
    width: 100%;
    max-width: 525px;
    padding-bottom: min(52.6%,276.15px);
    margin: 0 auto 20px;
    position: relative;
}
.market-fng-gauge .market-fng-gauge__meter {
    overflow: hidden;
    position: absolute;
    top: 0;
    height: 100%;
    box-sizing: content-box;
    width: 100%;
}

.market-fng-gauge__dial {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.market-fng-gauge__hand {
    width: 3.15%;
    position: absolute;
    bottom: 0;
    padding-bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    enable-background: 'new 0 0 10 124';
}

.market-fng-gauge__hand-base {
    width: 20%;
    height: 20%;
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}

.market-fng-gauge__hand-base:before {
    content: '';
    display: block;
    background: #fff;
    width: 100%;
    padding-bottom: 100%;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0 -15px 15px rgb(12 12 12 / 5%);
}

.market-fng-gauge__dial-number {
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
    width: 20%;
    height: 0;
    padding-top: 11.25%;
    transition: .5s color;
    color: #262626;
}

.market-fng-gauge__dial-number .market-fng-gauge__dial-number-value {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    font-family: inherit;
    font-size: 27px;
    line-height: 27px;
    font-weight: 800;
    text-align: center;
}

.market-fng-gauge__hand .market-fng-gauge__hand-svg {
    z-index: 2;
    transform-origin: bottom center;
    transform: rotate(0);
}

.market-fng-gauge__meter[data-index-label=greed] #extreme-greed {
    
}

.green-dial{
    fill: #b9ede9 !important;
    stroke: #3da672;
}

.market-fng-gauge__dial .dial-area {
    fill: #f7f7f7;
}

.market-fng-gauge__dial .dial-area-number-text, .market-fng-gauge__dial .dots {
    fill: #8c8b8b;
}

.market-fng-gauge .market-fng-gauge__historical {
    width: 100%;
}

.market-fng-gauge .market-fng-gauge__historical-item {
    margin-top: 4px;
    color: #262626;
    font-weight: 700;
    font-size: 13px;
}

.market-fng-gauge .market-fng-gauge__historical-item-label {
    color: #6e6e6e;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
}

.market-fng-gauge .market-fng-gauge__historical-item-index {
    display: flex;
}

.market-fng-gauge .market-fng-gauge__historical-item-index-label {
    width: calc(100% - 32px);
    text-transform: capitalize;
    display: inline-flex;
}
.market-fng-gauge .market-fng-gauge__historical-item[data-index-label=neutral] .market-fng-gauge__historical-item-index-label:before {
    content: 'neutral';
}
.market-fng-gauge .market-fng-gauge__historical-item-index-label:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 50%;
    border-bottom: 1px dashed #e6e6e6;
}
.market-fng-gauge .market-fng-gauge__historical-item[data-index-label=neutral] .market-fng-gauge__historical-item-index-value {
    background: #e6e6e6;
    border: 1px solid #6e6e6e;
}