/* -------------------- GLOBAL --------------------  */

body {
	position: relative;
	z-index: -1;
	background-color: #e5e5e5 !important;
}

.standard_icon {
	transform: scale(1.3335) !important;
}

.navbarBox {
	display: flex;
	justify-content: center;
}

@media screen and (max-width: 1224px) {
	.navbarBox {
		display: block;
		/* display: flex; */
		/* justify-content: center; */
	}
}

.baseColour {
	background-color: #e5e5e5 !important;
}

.whiteFont {
	color: white !important;
}

.greenOutline {
	border-color: #17ad37 !important;
}

.greenOutline:hover {
	color: #17ad37 !important;
}

.redOutline {
	border-color: #d70714 !important;
}

.redOutline:hover {
	color: #d70714 !important;
}

.greenCheckbox {
	color: green !important;
}

.underlinedInput {
	/* border-bottom: 1px solid black; */
	border-bottom: none !important;
	border-left: none !important;
	border-top: none !important;
	border-right: none !important;
	outline: none !important;
	width: 100% !important;
}

.blackText {
	color: black !important;
}

.iframeDashboard {
	border: none;
	/* height: 80%; */
}

.iframeText {
	text-align: center;
}

.fredIframe {
	height: 100%;
}

.redDot {
	background-color: red;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: inline-block;
	margin-left: 14px;
}

.greenDot {
	background-color: rgb(23, 219, 78);
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: inline-block;
	margin-left: 14px;
}

.greyDropdown {
	background: rgba(103, 116, 142, 0.2) !important;
	color: #344767 !important;
}

.bbvColour {
	background: linear-gradient(136.64deg, #d70714 1.59%, #4d3131 98.89%) !important;
	box-shadow: 0px 2px 6px rgba(220, 34, 101, 0.36) !important;
	border-radius: 8px !important;
}

.bbvGreen {
	background: linear-gradient(145.56deg, #17ad37 0.3%, #0e6620 94.23%) !important;
	color: white !important;
}

.portfolioButtonSelected {
	background: white !important;
	color: black !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 5.5px rgba(0, 0, 0, 0.06) !important;
	border-radius: 8px !important;
	font-size: 10px !important;
	width: 170px !important;
	height: 25px !important;
}

.portfolioButtonUnselected {
	background: white !important;
	color: black !important;
	border-radius: 8px !important;
	font-size: 10px !important;
	width: 170px !important;
	height: 25px !important;
}

.scroll-to-top-button {
	position: fixed;
	bottom: 170px;
	right: 50px;

	background-color: white;
	color: black;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	opacity: 1;
	transition: opacity 0.3s ease;
}

.bbvWhiteCard {
	background-color: white;
	border-radius: 15px;
}

.underline:hover {
	text-decoration: underline;
}

.redKey {
	color: #af0712;
	transform: scale(1.5);
	cursor: pointer;
}

.redKey:hover {
	transform: scale(1.9);
}

.greenText {
	color: #139a35 !important;
}

.bbvRed {
	background: linear-gradient(136.64deg, #d70714 1.59%, #4d3131 98.89%) !important;
}

.bbvGrey {
	background: rgba(103, 116, 142, 0.7) !important;
	color: white !important;
}

.flatpickr-day.selected {
	background-color: rgba(103, 116, 142, 0.7) !important;
	color: white;
}

.bbvLightGrey {
	background: #f2f5f7 !important;
	color: #3e5463 !important;
}

.bbvGold {
	background-image: linear-gradient(135deg, #eca231 0%, #9d6e27 90%) !important;
	border-radius: 8px;
	color: white !important;
}

.titleGrey {
	color: #344767 !important;
}

.greyOutline {
	color: rgba(103, 116, 142, 0.7) !important;
	border-color: rgba(103, 116, 142, 0.7) !important;
}

.darkGrey {
	color: #7e8ba9;
}

.redIcon {
	/* fill : linear-gradient(136.64deg, #d70714 1.59%, #4D3131 98.89%)!important; */
	color: #c1111c !important;
}

.flex {
	display: flex;
}

.optionSubmenu {
	z-index: 10000;
	top: -4.5%;
	left: 14.5%;
}

.popupTextStyle {
	font-size: 1rem !important;
	color: #7b809a !important;
}

.popupGrey {
	color: #344767 !important;
}

.centerImage {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.centerButton {
	display: block !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.helvetica {
	font-family: "Helvetica";
	font-style: normal;
	font-weight: 700;
}

.roboto {
	font-family: "Roboto" !important;
	font-style: normal !important;
	font-weight: 700 !important;
	/* font-size: 24px !important; */
	line-height: 140% !important;
}

.roboto_500 {
	font-family: "Roboto" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	/* font-size: 24px !important; */
	line-height: 140% !important;
}

.roboto_medium {
	font-family: "Roboto" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	/* font-size: 24px !important; */
	line-height: 140% !important;
}

h3.roboto {
	font-size: 36px;
}

.centerText {
	text-align: center;
}

.iconBox {
	width: 38px;
	height: 38px;
	/* Green Main */
	background: linear-gradient(145.56deg, #17ad37 0.3%, #0e6620 94.23%);
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.whiteIcon {
	color: white;
}

.stockList {
	background-color: white;
	padding: 5px !important;
	box-shadow: 0rem 0.5rem 1.625rem -0.25rem rgba(20, 20, 20, 0.15),
		0rem 0.5rem 0.5625rem -0.3125rem rgba(20, 20, 20, 0.06);
	width: 180px !important;
	text-align: center !important;
	max-height: 120px;
	overflow-y: auto;
	position: relative;
	z-index: 100;
	font-size: 12px;
}

.stockListItems {
	padding: 10px 15px 10px 15px !important;
}

.stockListItems:hover {
	background-color: rgba(103, 116, 142, 0.2) !important;
	opacity: 0.7;
	border-radius: 8px;
	color: black;
}

.tableHeading {
	color: white !important;
	opacity: 1 !important;
	font-size: 12px !important;
	font-family: Roboto !important;
}

.icon {
	width: 24px !important;
	height: 24px !important;
}

.icon:hover {
	transform: scale(1.1);
}

.dashed {
	border: none;
	border-top: 1px dashed #ccc;
	height: 0;
	width: 90%;
}

/* news componenet  */
.news_header {
	font-size: 16px;
}

.news_div {
	display: flex;
}

.news_image {
	width: 90%;
	height: 70%;
	object-fit: cover;
	border-radius: 20px;
}

.news_summary {
	font-size: 14px;
}

/* navbar */
.searchSymbolInputDiv {
	height: 38px !important;
	/* background-color: white !important; */
	border-radius: 8px;
	width: 202px;
	display: flex;
	align-items: center;
	/* padding-left: 10px; */
	padding-right: 20px;
	margin-right: 20px;
	margin-bottom: 10px;
	margin-top: -12px;
}

.bbvNavbar {
	position: relative;
	height: 740px;
	background-size: 100% 100%;
	background-image: url(./assets/images/navbarBG.png);
	border-radius: 20px;
}

.bbvNavbar.marketplace {
	background-image: url(./assets/images/mpNavbarBg.png);
}

.iconsDivMobile {
	display: none;
}

.marketplaceNav {
	background: linear-gradient(135deg, #ECA231 0%, #9D6E27 90%) !important;
	padding: 10px !important;
}

.iconsDivDesktop {
	display: block;
}

@media screen and (max-width: 533px) {
	.iconsDivDesktop {
		display: none !important;
	}

	.iconsDivMobile {
		display: block;
		margin-left: 0px;
		align-items: center;
	}

	.searchSymbolInputDiv {
		height: 38px !important;
		border-radius: 8px;
		width: 202px;
		display: flex;
		align-items: center;
		padding-right: 20px;
		margin-right: 20px;
		margin-bottom: 10px;
		margin-top: 0px;
	}
}

.overlay {
	background-image: url("./assets/images/Gold.png");
	width: 100% !important;
	height: 100% !important;
	background-size: 100% 100%;
	position: absolute;
	top: -100px;
}

.dashboardNavbarText {
	position: relative;
	z-index: 10;
	padding-left: 25px;
	padding-top: 60px;
}

.bbvCards {
	width: 130%;
	margin-top: 50px;
	margin-left: 30px;
	min-width: 350px;
	max-width: 690px;
}

.textOneNav {
	color: white;
	font-size: 48px;
	line-height: 140%;
}

.textTwoNav {
	color: white;
	font-size: 18px;
	margin-top: 15px;
}

.slider {
	display: none;
	height: 100% !important;
}

.sliderImg {
	/* width: auto; */
	height: auto;
	width: 100%;
	/* height: 380px; */
	background-size: contain;
	max-width: 430px;
	/* min-width: 360px; */
	margin-left: auto;
	margin-right: auto;
}

.sectorButtons {
	text-align: left !important;
	padding-left: 10px;
	padding-bottom: 5px;
}

@media (min-width: 768px) and (max-width: 1200px) {
	.overlay {
		background-image: url("./assets/images/Gold.png");
		width: 100% !important;
		height: 750px !important;
		background-size: cover;
		position: absolute;
		top: -100px;
		left: 0px;
	}
}

@media (min-width: 769px) and (max-width: 950px) {
	.bbvCards {
		width: 130%;
		margin-top: 50px;
		margin-left: 5px;
		min-width: 350px;
		max-width: 750px;
	}
}

@media screen and (max-width: 767px) {
	.bbvNavbar {
		background: linear-gradient(96.55deg, #9f0d16 -26.89%, #532828 170.92%);
		border-radius: 15px;
	}

	.overlay {
		display: none;
	}

	.bbvCards {
		display: none;
	}

	.dashboardNavbarText {
		width: 90% !important;
		padding-bottom: 30px;
		margin-top: 25px;
	}

	.bbvNavbar {
		height: auto;
		/* margin-bottom: 30px; */
	}

	.textOneNav {
		color: white;
		font-size: 32px;
		line-height: 140%;
	}

	.slider {
		width: 100%;
		height: 440px;
		/* height: 430px; */
		position: relative;
		z-index: 10;
		margin-bottom: 30px;
		margin-top: 30px;
		display: block;
	}

	.iframeText, .fredIframe {
		width: 100%;
	}
}

.upArrow {
	width: 2.4rem !important;
	height: 2.4rem !important;
}

.none {
	display: none !important;
}

.toTopButton {
	position: fixed !important;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black !important;
	bottom: 40px !important;
	left: auto !important;
	padding: 0.9rem 1.4rem 1.2rem 1.4rem;
	opacity: 0.75;

	color: #fff !important;
	width: 20px;
	height: 20px;
	border-radius: 8px !important;
}

.rightArrowIcon {
	display: none !important;
}

@media screen and (max-width: 992px) {
	.rightArrowIcon {
		display: flex !important;
		justify-content: center;
		align-items: center;
		top: 50%;
		background-color: grey !important;
		position: fixed !important;
		right: 0px !important;
		opacity: 0.75;
		color: #fff !important;
		/* height: 20px !important;  */
		width: 12px !important;
		min-width: 1px !important;
		border-top-left-radius: 8px !important;
		border-bottom-left-radius: 8px !important;
		border-top-right-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
		z-index: 10;
	}
}

.toTopButton:hover {
	background-color: #007fff !important;
}

/* .absolute{
    position: sticky !important;
    height: 100vh !important;
} */

.stockSideNav {
	border-radius: 8px;
	display: block !important;
	padding: 10px;
	height: calc(100vh - 84px) !important;
	z-index: 1;
	background-color: rgba(103, 116, 142, 0.7) !important;
	position: fixed;
	top: 84px;
	overflow-y: auto !important;
	right: 0;
	width: 35%;
	min-width: 320px;
}

@media screen and (max-width: 767px) {
	.stockSideNav {
		top: 150px;
		height: calc(100vh - 150px) !important;
	}
}

.sticky {
	top: 0;
	position: sticky !important;
	overflow-y: auto !important;
	max-height: 100vh;
}

/* dashboard */
.dashboardCards {
	background-color: white;
	width: calc(25% - 10px);
	border-radius: 15px;
	margin-top: 15px !important;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 30px;
}

.dashboardCardsLg {
	background-color: white;
	width: calc(50% - 7px);
	border-radius: 15px;
}

.sentimentsCard {
	background-color: white;
	width: 100%;
	border-radius: 15px;
	padding: 0 18px;

	/* margin-bottom: 20px !important; */
}

.newsGrid, .economicCalendar, .portfolioCard {
	padding: 18px;
}

.dashboardCardsSentiments {
	width: 49.5%;
}

.dashboardCardsSectors {
	width: 48%;
	display: inline-block !important;
}

.dashboardCardsImg {
	width: 100%;
	/* padding-left: 50px;
    padding-right: 50px; */
}

.news_sentiment_grid {
	height: 570px;
	margin-bottom: 20px;
}

.dashboardCardsMd {
	background-color: white;
	border-radius: 15px;
	width: 31%;
}

.dashboardCardsSpecialSmOne {
	background-color: white;
	border-radius: 15px;
	width: calc(50% - 7px);
}

.earnings_economicCalender {
	background-color: white;
	border-radius: 15px;
	width: calc(55% - 7px);
}

.earnings_earningsRelease {
	background-color: white;
	border-radius: 15px;
	width: calc(45% - 7px);
}

.dashboardCardsSpecialSmTwo {
	background-color: white;
	border-radius: 15px;
	width: 18%;
}

.dashboardsCardsFull {
	background-color: white;
	border-radius: 15px;
	width: 100%;
	margin-top: 15px !important;
}

.dashboardTrendCard {
	background-color: white;
	border-radius: 15px;
	width: 100%;
	padding: 0 18px;
}

.footerText {
	width: 50%;
}

.tickerTape {
	height: 50px;
}

.spxMeter {
	height: 315px;
}

.newsIframe {
	height: 100% !important;
	width: 100%;
	border: "none";
}

.portfolioCard {
	margin-bottom: 30px;
}

/* .trend_economic_grid {
	padding: 0 20px;
} */

@media screen and (max-width: 815px) {
	.tickerTape {
		height: 80px;
	}
}

@media screen and (max-width: 1000px) {
	.dashboardCards {
		width: 49%;
		background-color: white;
		border-radius: 15px;
		margin-top: 15px;
	}

	.dashboardCardsImg {
		width: 69%;
	}
}

@media screen and (max-width: 767px) {
	h3.roboto {
		font-size: 24px;
	}

	.economicCalendar {
		height: 590px;
	}

	.dashboardCardsSentiments {
		width: 100%;
	}

	.dashboardCardsSectors {
		width: 100%;
		display: inline-block !important;
	}

	/* .sentimentsCard{
        background-color: white;
        width: 100%;
        border-radius: 15px;
    } */
	.dashboardCards {
		width: 100%;
		background-color: white;
		border-radius: 15px;
		margin-top: 20px;
	}

	.newsIframe {
		height: 550px !important;
		margin-bottom: 0px;
		width: 100%;
		border: "none";
	}

	.newsGrid {
		max-height: 565px !important;
	}

	.dashboardCardsImg {
		width: 25%;
	}

	.news_sentiment_grid {
		height: 1200px;
		margin-bottom: 0px;
	}

	.dashboardCardsMd {
		background-color: white;
		border-radius: 15px;
		width: 100%;
	}

	.footerText {
		width: 80%;
	}

	/* .trend_economic_grid {
		height: 1499px !important;
	} */

	.dashboardCardsSpecialSmOne {
		background-color: white;
		border-radius: 15px;
		width: 100%;
	}

	.earnings_economicCalender {
		background-color: white;
		border-radius: 15px;
		width: 100%;
		height: 590px;
	}

	.earnings_earningsRelease {
		background-color: white;
		border-radius: 15px;
		width: 100%;
		height: 565px !important;
	}

	.dashboardCardsSpecialSmTwo {
		background-color: white;
		border-radius: 15px;
		width: 100%;
	}

	.dashboardCardsSpecialLg {
		background-color: white;
		border-radius: 15px;
		width: 100%;
	}

	.spxMeter {
		height: 400px;
	}

	.dashboardCardsLg {
		width: 100%;
		height: 600px;
		background-color: white;
		border-radius: 15px;
		/* margin-top: 15px; */
		margin-bottom: 15px;
	}
}

/* -------------------- AUTHENTICATION SCREENS -------------------- */

.topDiv {
	height: 100vh;
}

.backgroundImage {
	width: 100%;
	height: 100%;
	background-size: cover;
}

.outer {
	display: flex;
	align-items: center;
	justify-content: center;
}

.authCard {
	width: 60%;
	vertical-align: middle;
	background-color: white;
	min-width: 300px !important;
	padding-top: 15px;
}

.bbvColourText {
	background-image: linear-gradient(136.64deg, #ff0080 1.59%, #7928ca 98.89%) !important;
	background-clip: text;
	color: transparent;
}

.error {
	color: red;
	font-size: 1rem;
}

.termsAndConditions {
	font-size: 0.75rem !important;
}

.underline:hover {
	text-decoration: underline !important;
}

.underline {
	user-select: none;
}

@media screen and (max-width: 700px) {
	.dissapear {
		display: none;
	}

	.signInCard {
		width: 80%;
	}
}

@media screen and (max-width: 505px) {

	/*  mobile */
	.termsAndConditions {
		font-size: 0.635rem !important;
	}
}

@media screen and (max-width: 470px) {

	/*  mobile */
	/* .authCard{
        margin-top: 30%;
    } */
	.termsAndConditions {
		font-size: 0.5rem !important;
	}
}

/* -------------------- OPTIONS SCREENS -------------------- */

.optionButtons {
	max-width: 20vw !important;
	height: 68px;
	color: #7e8ba9 !important;
}

.sm-view {
	display: none !important;
}

.smallFont {
	font-size: 12px !important;
}

@media screen and (max-width: 600px) {

	/*  mobile */
	.smallFont {
		font-size: 9px !important;
	}

	.optionButtons {
		height: 54px;
	}
}

@media screen and (max-width: 800px) {
	.lg-view {
		display: none !important;
	}

	.sm-view {
		display: flex !important;
	}
}

/* -------------------- Stocks SCREENS -------------------- */
.activePagination {
	background-color: blue !important;
}

.dropDownButtons {
	width: 12vw;
	font-size: 11px !important;
	height: 18px !important;
}

.innerCard {
	margin-left: 20px;
	padding-top: 18px;
	padding-bottom: 18px;
	text-align: left;
}

.pagination {
	/* overflow-x:scroll; */
	/* width: 300px; */
	/* display: inline-block */
	/* background-color: red; */
}

.inline {
	display: inline;
}

.gem {
	margin-right: 20px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 140%;
}

.moneyMakingDiv {
	justify-content: space-between;
	display: flex;
	padding-top: 30px;
	min-width: 200px;
}

.moneyMakingDiv p {
	padding-right: 20px;
}

.moneyMakingDiv button {
	margin-left: 20px;
}

.rank {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 140%;
	/* or 20px */
	/* Green Main */
	background-image: linear-gradient(145.56deg, #17ad37 0.3%, #0e6620 94.23%) !important;
	background-clip: text;
	color: transparent;
}

.marketCap {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
}

.marketCapValue {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
}

.makeMoneyButton {
	width: 30%;
	height: 30px;
}

.mmmText {
	/* width: 40%; */
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 140%;
	text-align: right;
}

.sector {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	/* or 125% */
	letter-spacing: 0.39996px;
	color: #333333;
}

.portfolioAmt {
	color: #a0aec0;
}

.portfolioDiv {
	/* background-color: green; */
	display: flex;
	padding-bottom: 10px;
	padding-top: 10px;
	align-items: center;
	padding-left: 20px;
}

.stickRightDiv {
	margin-left: auto;
	margin-right: 0;
}

.portfolioSummaryDiv {
	display: flex;
	padding-bottom: 10px;
	padding-top: 10px;
	align-items: center;
	text-align: left;
}

.portfolioSummaryDiv>*:last-child {
	margin-left: auto;
	margin-right: 15px;
}

.test {
	text-align: left !important;
	margin: 0px;
}

.portfolioDiv {
	display: flex;
	padding-bottom: 10px;
	padding-top: 10px;
	align-items: center;
	padding-left: 20px;
}

.portfolioHeader {
	justify-content: space-between;
	display: flex;
	padding-top: 30px;
	min-width: 200px;
}

.portfolioHeader p {
	margin-left: 20px;
}

.portfolioHeader button {
	margin-right: 20px;
}

.portfolioDiv>*:last-child {
	/* justify-content: flex-end; */
	/* flex-grow: 2; */
	margin-left: auto;
	margin-right: 20px;
}

.sectorValue {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
}

.noColourButton {
	padding-top: 40px;
	padding-right: 10px;
	font-family: "Helvetica";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 150%;
	/* or 30px */
	/* display: flex;
    align-items: center;
    text-align: left; */
	margin-left: -10px !important;
}

.noColourButton:hover {
	text-decoration: underline;
}

.innerCard h5 {
	padding-bottom: 5px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 140%;
}

.innerCard p {
	font-family: "Helvetica";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
}

.searchResults {
	padding-top: 10px;
	font-family: Source Sans Pro;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	/* identical to box height, or 125% */
	letter-spacing: 0.5px;
	padding-bottom: 10px;
	color: #333333;
}

.analysisButton {
	background: #7e8ba9 !important;
	border-radius: 5px !important;
	color: white !important;
	text-transform: capitalize !important;
	width: 117px;
	height: 32px;
	margin-top: 22px !important;
}

.backButton {
	width: 40px;
	height: 30px;
	font-size: 13px !important;
	text-transform: none !important;
}

.fullSize {
	width: 100% !important;
	display: table !important;
}

.stocksDiv {
	background-color: white;
	padding-left: 10px;
	/* comment below to use original  */
	/* overflow-y: scroll !important;
    height: 800px; */
}

.pagination {
	padding-top: 10px;
	padding-right: 10px;
}

.loadingIcon {
	display: flex;
	justify-content: center;
	height: 80px;
	padding-top: 15px;
}

.fullWidth {
	width: 100%;
	height: 200px;
	border: none;
}

.rightColumn {
	padding-left: 30px;
}

.rightColumn p {
	padding-bottom: 4px;
}

@media screen and (max-width: 576px) {
	.rightColumn {
		padding-left: 10px;
	}

	.dropDownButtons {
		width: 100% !important;
		margin-bottom: 10px !important;
	}
}

@media (min-width: 576px) and (max-width: 991px) {
	.dropDownButtons {
		width: 20vw !important;
		font-size: 11px !important;
	}
}

@media (screen) and (max-width: 991px) {
	.mmmText {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 140%;
	}

	.gem {
		margin-right: 10px;
		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 140%;
	}
}

/* -------------------- Stocks Desc SCREENS -------------------- */
.technicalAnalysisOne {
	height: 270px;
}

.technicalAnalysisTwo {
	height: 400px;
}

@media screen and (max-width: 992px) {
	.technicalAnalysisOne {
		height: 230px;
	}

	.technicalAnalysisTwo {
		height: 500px;
	}
}

.anchorNews {
	color: #2d3748;
}

.anchorNews:hover {
	text-decoration: underline !important;
}

.stockGraphDiv {
	background-color: white;
	padding: 10px;
	border-radius: 15px;
	margin-top: 15px;
}

.stockGraphs {
	/* height: 90px; */
	max-width: 100%;
	height: auto;
}

.infoIcon {
	padding: 1px 7px 4px 7px;
	border-radius: 7px;
	text-align: center;
	/* margin-right: 32px; */
	height: 30px !important;
	width: 30px !important;
}

.infoIcon svg {
	/* padding-bottom: 4px; */
	vertical-align: middle;
	/* width: 14px !important;
    height: 14px !important; */
}

.infoIcon svg:hover {
	transform: scale(1.3);
}

.stocksDescDiv {
	overflow-x: auto !important;
	white-space: nowrap !important;
}

.stocksDescDiv button {
	margin-right: 15px !important;
}

.stockRow {
	padding: 12px 0px 12px 0px;
}

.stockRow>*:last-child {
	margin-left: auto;
	margin-right: 18px;
}

.stockDesc {
	padding-left: 18px;
	padding-right: 18px;
}

.stockDescType2>*:last-child {
	margin-left: auto;
	margin-right: 18px;
}

.stockDetailsTable {
	background-color: white;
	/* padding: 10px; */
	margin-top: 22px;
	border-radius: 16px;
}

.detailHeading {
	text-align: center;
}

@media (min-width: 600px) and (max-width: 1500px) {
	.stocksDescDiv button {
		width: 90px;
		font-size: 10px !important;
	}
}

@media screen and (max-width: 599px) {
	.stocksDescDiv button {
		width: 70px;
		font-size: 8px !important;
	}
}

/* -------------------- Dividend page-------------------- */
.dividendButtons {
	margin-left: 30%;
}

.dividendButtonsOne {
	margin-left: 5%;
	display: none;
}

@media (min-width: 920px) and (max-width: 1320px) {
	.dividendButtons {
		margin-left: 20%;
	}
}

@media screen and (max-width: 920px) {
	.dividendButtons {
		margin-left: 5%;
	}
}

@media screen and (max-width: 775px) {
	.dividendButtons {
		margin-left: 5%;
		display: none;
	}

	.dividendButtonsOne {
		/* margin-left: 5%; */
		display: block;
		padding-bottom: 20px;

		margin-left: 22px !important;
	}
}

.backDiv {
	display: flex;
	justify-content: flex-end;
	padding: 5px 0px 5px 0px;
	margin-left: auto;
	width: 200px;
}

.innerBackDiv {
	display: inherit;
	/* background-color: red; */
}

.backText {
	font-size: 14px;
	padding-top: 3px;
	user-select: none;
	user-select: none;
	-webkit-user-select: text;
	/* Safari fallback only */
	-webkit-user-select: none;
}

.expandIcon {
	rotate: 90deg;
	color: #7b809a !important;
	/* border-width: 0px !important; */
	width: 32px !important;
	height: 32px !important;
	-webkit-text-stroke-width: 6px !important;
	margin-left: 8px;
	vertical-align: auto;
}

.innerBackDiv:hover {
	pointer-events: visible;
	border-style: double;
	text-decoration: underline;
	/* padding: 3px; */
	border-color: rgba(103, 116, 142, 0.7);
}

.dividendBackground {
	background-size: 100% auto;
}

/* popup */

.popup {
	z-index: 9999;
	overflow-y: hidden;
	position: fixed;
	width: 100%;
	/* height: 90vh; */
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	min-width: 360px;
	display: flex;
	justify-content: center;
	align-items: center;

	/* centralising a fixed div   */
	left: 50%;
	transform: translate(-50%, 0);
	top: 0px;
	/* overflow: hidden; */

	animation-name: fadein;
	animation-duration: 0.5s;
	opacity: 1;
}

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.optionsPopup {
	width: 85% !important;
	height: 90%;
}

.popup-inner {
	overflow: auto;
	position: relative;
	background-color: #f2f2f2;
	/* z-index: 999999999999 !important; */
	border-radius: 10px;
	max-width: 650px;
	max-height: 100%;

	/* height: 45% !important; */
	/* overflow-y: scroll; */
	padding: 16px;
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.inputBox {
	background-color: white;
	height: 42px;
	width: 222px;
	display: flex;
	align-items: center;
	padding-left: 25px;
	padding-right: 20px;
}

.searchInputBox {
	background-color: white;
	height: 42px;
	width: 222px;
	display: flex;
	align-items: center;
	padding-left: 12px;
	padding-right: 20px;
}

.buttonInputBox {
	background-color: white;
	height: 42px;
	width: 222px;
	display: flex;
	align-items: center;
	padding-left: 13px;
}

.headerBox {
	background-color: #f2f5f7;
	height: 42px;
	width: 182px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	padding-left: 8px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.popup-inner::-webkit-scrollbar {
	display: none;
}

.transparent {
	background-color: transparent !important;
}

.optionPopupHeaders {
	font-size: 14px;
	padding: 3px;
}

.optionPopupCaptions {
	font-size: 18px;
}

.paymentPopupDiv {
	max-width: 400px !important;
	margin: 0px 20px 0px 20px !important;
}

.close-btn {
	position: absolute;
	top: 0px;
	right: 5px;
	width: 35px !important;
	height: 35px !important;
	color: #191919;
	cursor: pointer;
}

.info-close-btn {
	position: absolute;
	z-index: 1000000000 !important;
	top: 0px;
	right: -25px;
	width: 35px !important;
	height: 35px !important;
	color: #191919;
	cursor: pointer;
}

.popupWidth {
	width: 338px;
}

.popupLargeWidth {
	width: 500px;
}

@media screen and (max-width: 520px) {
	.popupLargeWidth {
		/* max-width: 500px; */
		width: 100%;
		min-width: 360px;
	}
}

.signInPopupCard {
	background-color: white !important;
	padding-top: 15px;
	padding-bottom: 20px;
	margin-top: 20px;
}

.optionPopupCard {
	background-color: #F2F2F2 !important;
	padding-top: 5px;
	padding-bottom: 20px;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 20px;
}

.popupCard {
	background-color: white !important;
	padding-top: 5px;
	padding-bottom: 20px;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 20px;
}

.optionPopupIcon {
	position: absolute;
	margin-top: 15px;
	/* left:20px; */

	/* top: 230px; */
}

.popup.transparent .close-btn {
	color: #fff;
}

/* options popup */
.optionsPopupGroupOne {
	background-color: white !important;
	border-radius: 10px;
	padding: 13px 4px 13px 4px;
	margin-top: 16px;
}

.whiteBox {
	background-color: white;
	border-radius: 10px;
	text-align: center;
	width: 85% !important;
	margin-left: auto;
	margin-right: auto;
}

.optionsInfo {
	display: block;
	/* margin-left: 20%; */
	margin-left: auto;
	margin-right: auto;
}

@media (min-width: 1200px) and (max-width: 1800px) {
	.optionsInfo {
		display: block;
		margin-left: 16%;
	}
}

/* Fear and greed */
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@font-face {
	font-family: "Helvetica Neue-Bold";
	font-style: normal;
	font-weight: 700;
	src: url("https://fonts.animaapp.com/HelveticaNeue-Bold") format("truetype");
}

@font-face {
	font-family: "Helvetica Neue-Regular";
	font-style: normal;
	font-weight: 400;
	src: url("https://fonts.animaapp.com/HelveticaNeue-Regular") format("truetype");
}

:root {
	--black: #000000;
	--dove-gray: #6e6e6e;
	--shark: #262626;
	--mercury: #e6e6e6;
	--font-size-m2: 16px;
	--font-size-xs: 12px;
	--font-size-l: 13px;
	--font-size-xxl: 38px;
	--font-family-helvetica_neue-bold: "Helvetica Neue-Bold", Helvetica;
	--font-family-helvetica_neue-regular: "Helvetica Neue-Regular", Helvetica;
}

* {
	box-sizing: border-box;
}

.frame {
	align-items: flex-start;
	background-color: #ffffff;
	border: 1px none;
	display: flex;
	width: 973px;
}

.hidden,
.hidden * {
	pointer-events: none;
	visibility: hidden;
}

.overlap-group1 {
	background-image: url(https://anima-uploads.s3.amazonaws.com/projects/63c25573a46b3a794001b583/releases/63c798398f9c48b53ef89f8e/img/group@1x.png);
	background-size: 100% 100%;
	height: 312px;
	position: relative;
	width: 973px;
}

.fear {
	left: 692px;
	letter-spacing: 0;
	line-height: normal;
	position: absolute;
	top: 194px;
	width: 70px;
}

.helveticaneue-bold-dove-gray-16px {
	color: var(--dove-gray);
	font-family: var(--font-family-helvetica_neue-bold);
	font-size: var(--font-size-m2);
	font-style: normal;
	font-weight: 700;
}

.neutral-1 {
	left: 692px;
	letter-spacing: 0;
	line-height: normal;
	position: absolute;
	top: 273px;
	width: 58px;
}

.address-1 {
	top: 179px;
	width: 70px;
}

.frame .address-3 {
	left: 692px;
	letter-spacing: 0;
	line-height: normal;
	position: absolute;
}

.helveticaneue-regular-normal-dove-gray-12px {
	color: var(--dove-gray);
	font-family: var(--font-family-helvetica_neue-regular);
	font-size: var(--font-size-xs);
	font-style: normal;
	font-weight: 400;
}

.neutral {
	left: 692px;
	letter-spacing: 0;
	line-height: normal;
	position: absolute;
	top: 115px;
	width: 64px;
}

.previous-close {
	left: 692px;
	letter-spacing: 0;
	line-height: normal;
	position: absolute;
	top: 22px;
	width: 79px;
}

.greed {
	left: 692px;
	letter-spacing: 0;
	line-height: normal;
	position: absolute;
	top: 36px;
	width: 79px;
}

.address-2 {
	top: 259px;
	width: 58px;
}

.address {
	top: 99px;
	width: 64px;
}

.frame-1 {
	align-items: flex-end;
	display: flex;
	height: 301px;
	left: 17px;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 632px;
}

.overlap-group {
	height: 320px;
	margin-bottom: -21px;
	position: relative;
	width: 631px;
}

.fear-1 {
	height: 158px;
	left: 91px;
	position: absolute;
	top: 7px;
	width: 188px;
}

.number-1 {
	color: #344767;
	font-family: var(--font-family-helvetica_neue-regular);
	font-size: var(--font-size-xxl);
	font-weight: 400;
	left: 290px;
	letter-spacing: 0;
	line-height: 38px;
	position: absolute;
	text-align: center;
	top: 260px;
	white-space: nowrap;
	width: 46px;
}

.extreme-greed-text {
	height: 87px;
	left: 535px;
	position: absolute;
	top: 150px;
	width: 69px;
}

.frame .extreme {
	height: 203px;
	position: absolute;
	top: 91px;
	width: 172px;
}

.extreme-greed {
	left: 459px;
}

.greed-text {
	height: 39px;
	left: 423px;
	position: absolute;
	top: 39px;
	width: 62px;
}

.rectangle {
	background-color: #f2f2f2;
	border-radius: 50px;
	box-shadow: 0px 4px 4px #00000040;
	height: 73px;
	left: 277px;
	position: absolute;
	top: 247px;
	width: 74px;
}

.neutral-2 {
	height: 113px;
	left: 254px;
	position: absolute;
	top: 0;
	width: 124px;
}

.extreme-fear {
	left: 0;
}

.fear-text {
	height: 36px;
	left: 150px;
	position: absolute;
	top: 41px;
	width: 53px;
}

.extreme-fear-text {
	height: 87px;
	left: 26px;
	position: absolute;
	top: 151px;
	width: 66px;
}

.vector {
	height: 172px;
	left: 326px;
	position: absolute;
	top: 90px;
	width: 115px;
}

.neutral-text {
	height: 19px;
	left: 270px;
	position: absolute;
	top: 18px;
	width: 91px;
}

.group-1 {
	height: 171px;
	left: 131px;
	position: absolute;
	top: 120px;
	width: 369px;
}

.greed-1 {
	height: 158px;
	left: 353px;
	position: absolute;
	top: 7px;
	width: 187px;
}

.frame-349 {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: 50px;
	left: 896px;
	opacity: 0.55;
	position: absolute;
	top: 23px;
	width: fit-content;
}

.frame-347 {
	align-items: center;
	background-color: #ffb9a180;
	border: 1px solid;
	border-color: #cd6200;
	border-radius: 50px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	padding: 6px;
	width: 28px;
}

.number {
	letter-spacing: 0;
	line-height: normal;
	margin-top: -1px;
	width: fit-content;
}

.helveticaneue-regular-normal-shark-13px {
	color: var(--shark);
	font-family: var(--font-family-helvetica_neue-regular);
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 400;
}

.frame-34 {
	align-items: center;
	background-color: var(--mercury);
	border: 1px solid;
	border-color: var(--dove-gray);
	border-radius: 50px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	padding: 6px;
	width: 28px;
}

.frame-345 {
	align-items: center;
	background-color: #b9ede9;
	border: 1px solid;
	border-color: #3da672;
	border-radius: 50px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	padding: 6px;
	width: 28px;
}

.group {
	height: 293px;
	left: 0;
	position: absolute;
	top: 0;
	width: 973px;
}

/* portfolio page */

.portfolioDropdown {
	color: black !important;
}

.dateOfExpiryButton {
	/* margin-right: 450px !important; */
}

.portfolioPageCardOne {
	width: 27%;
	background-color: white;
	border-radius: 15px;
	height: 430px;
}

.portfolioPageCardTwo {
	width: 38%;
	/* background-color: #fff !important; */
	border-radius: 15px;
}

.portfolioInput {
	user-select: none;
	user-select: none;
	-webkit-user-select: text;
	/* Safari fallback only */
	-webkit-user-select: none;
}

.portfolioInput:hover {
	pointer-events: visible;
	border-style: double;
	text-decoration: underline;
}

.portfolioPageCardThree {
	width: 33%;
	background-color: white;
	border-radius: 15px;
	height: 430px;
}

/* .diversification {
    background-color: white;
    border-radius: 15px;
} */

.centerPieChart div {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.pieChart div {
	width: 100% !important;
}

.portfolioTableText {
	display: inline;
	padding-right: 30px;
	color: #8392ab;
}

.rowBoxes {
	border-bottom-style: dotted !important;
	border-bottom-width: 1px !important;
	border-bottom: #000000 !important;

	border-bottom-color: red !important;
}

@media screen and (max-width: 1100px) {
	.portfolioPageCardOne {
		width: 100%;
	}

	.portfolioPageCardTwo {
		margin-top: 20px !important;
		width: 48%;
	}

	.portfolioPageCardThree {
		margin-top: 20px !important;
		width: 48%;
	}
}

.portfolioCellText {
	font-size: 12px;
}

@media screen and (max-width: 750px) {
	.portfolioPageCardOne {
		width: 100%;
	}

	.portfolioPageCardTwo {
		margin-top: 20px !important;
		width: 100%;
	}

	.portfolioPageCardThree {
		margin-top: 20px !important;
		width: 100%;
	}
}

.portfolioGainedText {
	font-size: 14px;
}

.blurred {
	filter: blur(0.75rem);
}

.background-border {
	display: flex;
	margin: 0 auto;
	justify-content: center;
	position: relative;
}

.background-border::before {
	content: "";
	display: block;
	position: absolute;
	height: 2px;
	background: #7b809a;
	width: 60%;
	top: 50%;
}

.background-border>* {
	padding: 0 15px;
	background: #fff;
	position: relative;
}

.MuiInputLabel-outlined {
	font-size: 1rem !important;
	line-height: 1rem !important;
	transform: translate(14px, -9px) !important;
}

.tnc {
	max-width: 834px;
}

.okButton {
	font-size: 10px !important;
}

.checkbox {
	width: 28px;
	height: 28px;
	border-radius: 18px !important;
	margin-left: -4px !important;
	outline-color: black !important;
	/* background-color:green !important; */
	/* fill: green; */
}

.checkbox:hover {
	background-color: none !important;
}

.tnc p {
	margin-bottom: 15px;
	font-size: 16px;
}

.main-content a {
	color: inherit;
	text-decoration: underline !important;
}

.hiw_card {
	background: linear-gradient(138.87deg, #220B05 7.81%, #141727 95.71%);
	border-radius: 20px;
	color: #FFF;
	height: 100%;
}

.mp-perks {
	background: url(./assets/images/mp_perks_bg.png) top right no-repeat !important;
	padding: 260px 10% !important;
}

.mp-perks .content {
	background: rgba(255, 255, 255, 0.8);
	box-shadow: 0px 2px 6px rgba(151, 172, 198, 0.25);
	backdrop-filter: blur(12px);
	max-width: 450px;
	padding: 25px;
	border-radius: 20px;
}

.anchor {
	cursor: pointer;
}

.StripeElement {
	display: block;
	margin: 10px 0 20px 0;
	max-width: 500px;
	padding: 10px 14px;
	font-size: 1em;
	font-family: "Source Code Pro", monospace;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
		rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border: 0;
	outline: 0;
	border-radius: 4px;
	background: white;
}

.collection-container {
	cursor: pointer;
	max-width: 300px !important;
}

.dark-background {
	background: #220B05 !important;
}

.main-content.dark-background {
	padding: 30px;
}

.dark-background * {
	color: #FFF !important;
}

.nft-details {
	border: 1px solid #FFF;
	border-radius: 10px
}

.nft-details .MuiAccordion-root {
	box-shadow: 0px 2px 1px -1px rgba(255, 255, 255, 0.2);
}

.nft-details .MuiAccordionDetails-root {
	padding: 0 !important;
}

.nft-details .MuiAccordionSummary-root {
	padding: 0 !important;
}

@media screen and (max-width: 767px) {
	.collection-container {
		max-width: none !important;
	}

	.mp-perks {
		background-size: contain !important;
		padding: 260px 0 0 !important;
	}

	.main-content.dark-background { 
		padding: 0
	}
}

@media screen and (max-width: 1080px) {
	.mp-perks {
		display: flex;
		justify-content: center;
	}
}